@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;0,800;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,600,400italic');

/* .secondary,
.MuiButton-containedSecondary {
  background-color: #f08213 !important;
}

.primary,
.MuiButton-containedPrimary {
  background-color: #191970 !important;
} */

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

html,
body {
  /* min-height: 100vh; */
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  padding-top: 57px;
}

.centered {
  text-align: center;
}

.editorClassName {
  border: 1px solid #ccc;
}

.rec-pagination {
  display: none !important;
}

.MuiButton-textPrimary {
  color: #fff !important;
}

.rec-arrow {
  /* background-color: var(--blue) !important; */
  background-color: #333 !important;
  transition: all 0.3s ease;
  font-size: 1.5rem !important;
  color: white !important;
  width: 1.4rem !important;
  height: 1.4rem !important;
  min-width: 1.4rem !important;
  line-height: 1.4rem !important;
}

.search-input input[type='search'] {
  width: 100%;
  border: 1px solid #e6e6e6;
  padding: 1.1rem;
  border-radius: 8px;
  font-size: 1.1rem;
}

.MuiList-root {
  padding: 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.css-1fyyp8j-MuiGrid-root {
  padding-right: 0 !important;
}

.sc-hkwDye {
  z-index: 2000 !important;
}

.walletHolder {
  cursor: pointer;
}

.active {
  background-color: #f08213 !important;
  color: #fff !important;
  padding: 3px 5px;
  border-radius: 3px;
}

.lastTab,
#simple-tab-4 {
  position: relative !important;
}

#WEB3_CONNECT_MODAL_ID,
.web3modal-modal-lightbox {
  z-index: 2000 !important;
}

.embed-tool__caption,
.cdx-quote__caption,
.image-tool__caption {
  display: none;
}

.MuiAccordionSummary-root {
  margin-left: 7px !important;
}

.css-mgur8:hover {
  color: 'midnightblue' !important;
}

.codex-editor__redactor {
  padding-bottom: 100px !important;
}

/* .rec-item-wrapper {
  padding: 0 !important;
  width: 100%;
} */

.rec.rec-arrow:disabled {
  visibility: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 30px;
  left: 20%;
  right: 20%;
  width: 60%;
  height: 60%;
}

@media (max-width: 1200px) {
  .video-container iframe,
  .video-container object,
  .video-container embed {
    width: 80%;
    height: 80%;
    left: 10%;
    right: 10%;
  }
}

@media (max-width: 768px) {
  .video-container iframe,
  .video-container object,
  .video-container embed {
    width: 95%;
    height: 95%;
    left: 0.5%;
    right: 0;
  }
}

@media (max-width: 768px) {
  .wallet-button {
    display: none !important;
  }
  .walletHolder {
    cursor: none;
  }
}
