.main-title {
  color: #f7931a;
  font-weight: bold;
  font-size: 42px;
  font-style: italic;
  line-height: 5px;
  letter-spacing: -1px;
}

@media only screen and (max-width: 1246px) {
  .home-box {
    margin-bottom: 4rem;
  }
}

@media only screen and (max-width: 959px) {
  .home-box {
    background-color: #666;
    padding-bottom: 2rem;
  }
  .home-box p {
    color: #fff;
  }
}

@media only screen and (max-width: 750px) {
  .home-box p {
    color: #fff;
  }
}
