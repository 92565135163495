.cardz {
  padding: 0.75rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  /* margin-bottom: 15px; */
  min-height: 38rem;
  max-width: 340px;
}

.campaign-card-buttons {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.card-img {
  max-height: 291px;
  min-height: 290px;
  max-width: 350px;
  width: 100%;
  overflow: hidden;
  object-fit: contain;
}

@media only screen and (max-width: 750px) {
  .campaign-card-buttons {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 599px) {
  .campaign-card-buttons {
    display: block !important;
    width: 100% !important;
    margin: 0 auto !important;
  }
}

@media only screen and (max-width: 500px) {
  .campaign-card-buttons {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media (max-width: 366px) {
  .card-img {
    max-height: 150px !important;
    min-height: 100px !important;
  }
}

@media (max-width: 320px) {
  .MuiContainer-maxWidthLg {
    padding: 0 !important;
  }
}
