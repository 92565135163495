:root {
  --primary: midnightblue;
  --secondary: #f7931a;
  --warning: #ffd028;
  --danger: #eb3f27;
  --success: #75fa83;
  --white: #fdfdfd;
  --dark: #181717;
}

.btn {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  transition: transform 0.3s ease;
}

.btn:hover {
  transform: translateY(-3px);
}

.btn--primary--solid {
  width: 90%;
  border: none;
  display: inline-block;
  margin-top: 20px;
  background-color: var(--primary);
  color: var(--white);
}

.btn--secondary--solid {
  width: 90%;
  border: none;
  display: inline-block;
  margin-top: 20px;
  background-color: var(--secondary);
  color: var(--white);
}

.btn--warning--solid {
  background-color: var(--warning);
  color: var(--white);
}

.btn--danger--solid {
  background-color: var(--danger);
  color: var(--white);
}

.btn--success--solid {
  background-color: var(--success);
  color: var(--white);
}

.btn--white--solid {
  background-color: var(--primary);
  color: var(--primary);
}

.btn--dark--solid {
  background-color: var(--dark);
  color: var(--white);
}

.btn--primary--outline {
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.btn--secondary--outline {
  background-color: transparent;
  border: 2px solid var(--secondary);
  color: var(--secondary);
}

.btn--warning--outline {
  background-color: transparent;
  border: 2px solid var(--warning);
  color: var(--warning);
}

.btn--danger--outline {
  background-color: transparent;
  border: 2px solid var(--danger);
  color: var(--danger);
}

.btn--success--outline {
  background-color: transparent;
  border: 2px solid var(--success);
  color: var(--success);
}

.btn--medium {
  padding: 10px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 15px 30px;
  font-size: 20px;
}
